export const ActionTypes = {
  SET_SIDEBAR: "SET_SIDEBAR",
  SET_LOGIN_USER: "SET_LOGIN_USER",
  REMOVE_LOGIN_USER: "REMOVE_LOGIN_USER",
  ADD_AUTHTOKEN: "ADD_AUTHTOKEN",
  REMOVE_AUTHTOKEN: "REMOVE_AUTHTOKEN",
  RUN_API_MODE: "RUN_API_MODE",
  COMPANY_PROJECT_DATA: "COMPANY_PROJECT_DATA",
  CUSTOMER_TAB_INDEX: "CUSTOMER_TAB_INDEX",
  LEAD_COUNT: "LEAD_COUNT"
};
