import React, { useState, Component, Suspense } from "react";
import {
  HashRouter,
  Route,
  Routes,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";
import "./scss/style.scss";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import ProtectedRoutes from './auth/ProtectedRoutes';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/forgotPassword/ForgotPasswordForm")
);
// const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route
              path="/"
              name="Login Page"
              element={<Login />}
            // render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/forgotPassword"
              name="Forgot Password"
              element={<ForgotPassword />}
            />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            {/* <ProtectedRoutes path="*" name="dashboard" element={<DefaultLayout />} /> */}
            <Route exact path='/' element={<ProtectedRoutes />}>
              <Route exact path='*' name="leads" element={<DefaultLayout />} />
            </Route>
          </Routes>
        </Suspense>
      </HashRouter>
    );
  }
}

export default App;
