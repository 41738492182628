import { ActionTypes } from "../constants/action_types"

const initialValue = {}

export const companyProjectData = (state = initialValue, { type, data }) => {
    switch (type) {
        case ActionTypes.COMPANY_PROJECT_DATA:
            return data
        default:
            return state
    }
}