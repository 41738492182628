import { combineReducers } from "redux";
import { sidebarReducer } from "./sidebarReducer";
import { userReduer } from "./userReducer";
import { AuthReduer } from "./authTokenReducer";
import { apiModeReducer } from "./runAPIReducer"
import { companyProjectData } from './companyProjectReducer'
import { customerTabValue } from './customerTabReducer'
import { leadCountData } from './leadCountReducer'

const reducers = combineReducers({
  sidebarStatus: sidebarReducer,
  logedUser: userReduer,
  authToken: AuthReduer,
  apiModeReducer: apiModeReducer,
  companyProjectData: companyProjectData,
  customerTabValue: customerTabValue,
  leadCountData: leadCountData,
});

export default reducers;
