import React from "react";
import Auth from "./Auth";
import { Route, Redirect } from "react-router-dom"
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoutes = ({ component: Component, ...rest }) => {
  return (
    Auth.getAuth() ? (
      <Outlet />
    ) : (
      <Navigate to={{
        pathname: "/",
      }} />
    )
  );
};

export default ProtectedRoutes;
