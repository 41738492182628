import { ActionTypes } from '../constants/action_types'
const initialVal = 0;

export const leadCountData = (state = initialVal, { type, value }) => {
    switch (type) {
        case ActionTypes.LEAD_COUNT:
            return value
        default:
            return state
    }
}